import { mapState } from 'pinia'
import { useIndustryStore } from '@/stores'
import { ENUM_MODALITY } from '@/modules/industry/enums'

export const editProductsTable = {
  props: {
    search: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    ENUM_MODALITY,
    products: [],
    noDataText: 'Não há dados'
  }),
  components: {
    FieldToggle: () => import('@/components/atomic-components/atoms/FieldToggle.vue')
  },
  computed: {
    ...mapState(useIndustryStore, ['selectedOffersGroup', 'offer']),

    productsCount() {
      const count = this.products.length
      return `${this.offer.products_group ? count - 1 : count} produto${count > 1 ? 's' : ''}`
    },

    items() {
      return Object.values(this.$v.products.$each.$iter)
    },

    isEditingMercapromo() {
      return this.selectedOffersGroup.modality === ENUM_MODALITY.MERCAPROMO
    },

    isEditingMercatrade() {
      return this.selectedOffersGroup.modality === ENUM_MODALITY.MERCATRADE
    }
  },
  watch: {
    'offer.products_group'() {
      this.createEditableItems()
    }
  },
  mounted() {
    this.createEditableItems()
  },
  methods: {
    createEditableItems() {
      this.products = []
      if (this.offer.products_group) {
        const valuesToBeCopied = this.offer[this.offer.offer_type][0]
        this.products.push({
          ...valuesToBeCopied,
          ean: '',
          product_name: '[Nome do grupo não editável]',
          disabledEdit: false,
          index: 0
        })
        this.offer[this.offer.offer_type].map((item, index) => {
          this.products.push({ ...valuesToBeCopied, ean: item.ean, product_name: item.product_name, disabledEdit: true, index: index + 1 })
        })
      } else {
        this.offer[this.offer.offer_type].map((item, index) => {
          this.products.push({ ...item, disabledEdit: false, index })
        })
      }
    },

    copyValue(field, value) {
      if (!this.offer.products_group) return
      this.products.slice(1).forEach(product => (product[field] = value))
    },

    isProductNameRequired(productUnderValidation) {
      if (this.offer.products_group) return false
      const originalProduct = this.offer[this.offer.offer_type].find(offer => offer.ean === productUnderValidation.ean)
      if (originalProduct) return !!originalProduct.product_name
      return false
    },

    nameAndLabelTooltip(productV) {
      if (!productV.notOnlySpace) return 'Não pode conter apenas espaços.'
      else if (!productV.required) return 'Preenchimento obrigatório para esse produto.'
      return ''
    },

    validate() {
      this.$v.$reset()
      this.$v.$touch()
      const isValid = !this.$v.$invalid
      return isValid
    }
  }
}
