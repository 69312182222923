<template>
  <mf-card-container title="Configurações da campanha">
    <div class="title-description-container">
      <div style="min-width: 384px;">
        <label for="offer-name">Título</label>
        <div class="mt-2">
          <v-text-field
            id="offer-name"
            v-model="offer.name"
            dense
            outlined
            :error-messages="nameErrors"
            :counter="200"
            :disabled="disabled"
            maxlength="200"
            placeholder="Título da campanha"
            @change="nameErrors = ''"
          />
        </div>
      </div>
      <div style="min-width: 700px;">
        <label for="offer-description">Descrição</label>
        <div class="mt-2">
          <v-text-field id="offer-description" :value="offer.name" dense outlined :counter="200" disabled maxlength="200" placeholder="Descrição" />
        </div>
      </div>
    </div>
    <v-divider v-if="isEditingOnlyOneOffer"></v-divider>
    <div v-if="isEditingOnlyOneOffer" class="mt-2">
      <label for="offer-units">Lojas selecionadas</label>
      <div class="mt-2">
        <v-text-field
          id="offer-units"
          v-model="offer.units"
          dense
          outlined
          :hint="unitsHint"
          persistent-hint
          :error-messages="unitErrors"
          :disabled="disabled"
          placeholder="Lojas"
          @change="unitErrors = ''"
        />
      </div>
    </div>

    <div v-if="isEditingOnlyOneOffer" class="mt-2">
      <label for="offer-unit-tags">Tag das lojas</label>
      <div class="mt-2">
        <v-text-field
          id="offer-unit-tags"
          v-model="offer.unit_tags"
          dense
          outlined
          :hint="unitTagsHint"
          persistent-hint
          :error-messages="unitTagsErrors"
          :disabled="disabled"
          placeholder="Tag das lojas"
          @change="unitTagsErrors = ''"
        />
      </div>
    </div>
    <v-checkbox
      v-if="isEditingOnlyOneOffer"
      v-model="forceUnitsUpdate"
      label="Forçar atualização das lojas"
      hint="Isso fará com que possíveis novas lojas sejam incluídas nessa oferta."
      persistent-hint
    />
  </mf-card-container>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useIndustryStore } from '@/stores'
import { ENUM_MODALITY } from '@/modules/industry/enums'

export default {
  name: 'HeaderTradeOffer',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    ENUM_MODALITY,
    forceUnitsUpdate: false,
    nameErrors: [],
    unitErrors: [],
    unitTagsErrors: []
  }),
  computed: {
    ...mapState(useIndustryStore, ['selectedOffersGroup']),
    ...mapWritableState(useIndustryStore, ['offer']),

    isEditingOnlyOneOffer() {
      return this.selectedOffersGroup.offers.length === 1
    },

    isEditingMercapromo() {
      return this.selectedOffersGroup.modality === ENUM_MODALITY.MERCAPROMO
    },

    isEditingMercatrade() {
      return this.selectedOffersGroup.modality === ENUM_MODALITY.MERCATRADE
    },

    unitsHint() {
      const allUnitsText = this.isEditingMercapromo ? 'Todas' : 'Sempre Todas as Lojas'
      return `Digite '${allUnitsText}' para incluir todas as lojas ou digite os ids das lojas separados por vírgula.`
    },

    unitTagsHint() {
      return 'Digite as tags das lojas separadas por vírgula. Como por exemplo: Paraná,São Paulo'
    },

    isUnitsFilledWithUnitIds() {
      return this.offer.units && this.offer.units !== 'Todas' && this.offer.units !== 'Sempre Todas as Lojas'
    }
  },
  methods: {
    scrollWindowToError(querySelector) {
      this.$nextTick(() => {
        let element = document.querySelector(querySelector)
        if (element) {
          this.$vuetify.goTo(element, { offset: 200 })
        } else {
          this.$snackbar({ message: 'Preencha os campos corretamente.', snackbarColor: 'error' })
          window.scrollTo(0, 0)
        }
      })
    },

    formatUnits() {
      if (this.isUnitsFilledWithUnitIds) {
        this.offer.units = this.offer.units.replace(/[\[\]'"]/g, '')
        this.offer.units = this.offer.units.split(',').map(unit => unit.trim())
        this.offer.units = `[${this.offer.units.map(unit => `"${unit}"`).join(',')}]`
      }
    },

    validate() {
      this.nameErrors = ''
      this.unitErrors = ''
      let isValid = true

      if (!this.offer.name || this.offer.name.length <= 0) {
        this.nameErrors = 'Dê um nome para essa oferta.'
        isValid = false
        return
      }

      if (this.offer.name.length < 2) {
        this.nameErrors = 'O título possui menos de 2 caracteres.'
        isValid = false
        return
      }

      if (/^\s*$/.test(this.offer.name)) {
        this.nameErrors = 'Não é permitido salvar apenas espaços.'
        isValid = false
        return
      }

      if (/^\s|\s$/.test(this.offer.name)) {
        this.nameErrors = 'Não é permitido espaços no início ou no fim.'
        isValid = false
        return
      }

      if (this.isEditingOnlyOneOffer) {
        if (!this.offer.units && !this.offer.unit_tags) {
          this.unitErrors = 'É obrigatório incluir ao menos loja ou tag.'
          this.unitTagsErrors = 'É obrigatório incluir ao menos loja ou tag.'
          isValid = false
          return
        }

        if (this.offer.units) {
          this.formatUnits()
          if (!this.offer.units || this.offer.units.length <= 0) {
            this.unitErrors = 'É obrigatório incluir ao menos loja. Digite TODAS para incluir todas.'
            isValid = false
            return
          }
        }
      }

      if (!isValid) {
        this.scrollWindowToError('.error--text')
      }

      return isValid
    }
  }
}
</script>

<style lang="scss" scoped>
.title-description-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
</style>
