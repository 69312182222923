<template>
  <mf-card-container :title="productsCount">
    <mf-info-card
      v-if="search"
      class="mb-2"
      icon="warning"
      variant="warning"
      :description="`Exibindo resultados para '${search}'. Para visualizar/editar todos os produtos, limpe o campo de busca.`"
    />
    <v-data-table :headers="headers" :items="items" :search="search" :no-data-text="noDataText" :no-results-text="noDataText">
      <template #item.product_name.$model="{ item }">
        <field-toggle
          v-model="item.product_name.$model"
          text
          :disabled="disabled || item.$model.disabledEdit || offer.products_group"
          :error="item.product_name.$error"
          :row="item.$model.index"
          :column="0"
          :tooltip="item.product_name.$error"
          :tooltip-text="nameAndLabelTooltip(item.product_name)"
        />
      </template>

      <template #item.label_pdv.$model="{ item }">
        <field-toggle
          v-model="item.label_pdv.$model"
          text
          :disabled="disabled || item.$model.disabledEdit"
          :error="item.label_pdv.$error"
          :row="item.$model.index"
          :column="1"
          :counter="100"
          :tooltip="item.label_pdv.$error"
          :tooltip-text="nameAndLabelTooltip(item.label_pdv)"
          @input="newValue => copyValue('label_pdv', newValue)"
        />
      </template>

      <template #item.price_1.$model="{ item }">
        <field-toggle
          v-model="item.price_1.$model"
          money
          :disabled="disabled || item.$model.disabledEdit"
          :error="item.price_1.$error"
          :row="item.$model.index"
          :column="2"
          :tooltip="item.price_1.$error"
          tooltip-text="Preenchimento obrigatório e precisa ser maior ou igual ao preço 2."
          @input="newValue => copyValue('price_1', newValue)"
        />
      </template>

      <template #item.price_2.$model="{ item }">
        <field-toggle
          v-model="item.price_2.$model"
          money
          :disabled="disabled || item.$model.disabledEdit"
          :error="item.price_2.$error"
          :row="item.$model.index"
          :column="3"
          :tooltip="item.price_2.$error"
          tooltip-text="Preenchimento obrigatório e precisa ser menor ou igual ao preço 1."
          @input="newValue => copyValue('price_2', newValue)"
        />
      </template>

      <template #item.rebate.$model="{ item }">
        <field-toggle
          v-model="item.rebate.$model"
          money
          :disabled="disabled || item.$model.disabledEdit"
          :error="item.rebate.$error"
          :row="item.$model.index"
          :column="4"
          :tooltip="item.rebate.$error"
          tooltip-text="Preenchimento obrigatório e precisa ser menor ou igual ao preço 1."
          @input="newValue => copyValue('rebate', newValue)"
        />
      </template>

      <template #item.minimum_per_client.$model="{ item }">
        <field-toggle
          v-model="item.minimum_per_client.$model"
          :disabled="disabled || item.$model.disabledEdit"
          :error="item.minimum_per_client.$error"
          :row="item.$model.index"
          :column="5"
          :tooltip="item.minimum_per_client.$error"
          tooltip-text="Mínimo precisa ser menor que máximo."
          @input="newValue => copyValue('minimum_per_client', newValue)"
        />
      </template>

      <template #item.maximum_per_client.$model="{ item }">
        <field-toggle
          v-model="item.maximum_per_client.$model"
          :disabled="disabled || item.$model.disabledEdit"
          :error="item.maximum_per_client.$error"
          :row="item.$model.index"
          :column="6"
          :tooltip="item.maximum_per_client.$error"
          tooltip-text="Máximo precisa ser maior que mínimo."
          @input="newValue => copyValue('maximum_per_client', newValue)"
        />
      </template>
    </v-data-table>
  </mf-card-container>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { price1Validation, price2Validation, rebateValidation, minMaxPerClientValidation, notOnlySpace } from '@/modules/industry/helpers/productValidators.js'
import { editProductsTable } from '@/modules/industry/mixins/edit-products-table'

export default {
  name: 'AbsoluteDiscountTable',
  mixins: [editProductsTable],
  computed: {
    headers() {
      let headers = [
        {
          text: 'EAN',
          align: 'start',
          sortable: false,
          value: 'ean.$model',
          width: '6%'
        },
        {
          text: 'Nome',
          align: 'start',
          sortable: false,
          value: 'product_name.$model',
          width: '23%'
        }
      ]

      if (this.isEditingMercapromo) {
        headers = [
          ...headers,
          {
            text: 'Label PDV',
            align: 'start',
            sortable: false,
            value: 'label_pdv.$model',
            width: '23%'
          }
        ]
      }

      headers = [
        ...headers,
        {
          text: 'Preço 1',
          align: 'start',
          sortable: false,
          value: 'price_1.$model',
          width: '10%'
        },
        {
          text: 'Preço 2',
          align: 'start',
          sortable: false,
          value: 'price_2.$model',
          width: '10%'
        },
        {
          text: 'Reembolso',
          align: 'start',
          sortable: false,
          value: 'rebate.$model',
          width: '10%'
        },
        {
          text: 'Mín. CPF',
          align: 'start',
          sortable: false,
          value: 'minimum_per_client.$model',
          width: '9%'
        },
        {
          text: 'Máx. CPF',
          align: 'start',
          sortable: false,
          value: 'maximum_per_client.$model',
          width: '9%'
        }
      ]
      return headers
    }
  },
  validations() {
    return {
      products: {
        $each: {
          ean: {},
          product_name: {
            notOnlySpace,
            required: requiredIf(editingProduct => this.isProductNameRequired(editingProduct))
          },
          label_pdv: {
            notOnlySpace,
            required: requiredIf(() => {
              return this.isEditingMercapromo
            })
          },
          price_1: {
            price1Validation: price1Validation()
          },
          price_2: {
            price2Validation: price2Validation()
          },
          rebate: {
            rebateValidation: rebateValidation()
          },
          minimum_per_client: {
            minMaxPerClientValidation
          },
          maximum_per_client: {
            minMaxPerClientValidation
          }
        }
      }
    }
  }
}
</script>
