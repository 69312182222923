var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mf-card-container',{attrs:{"title":_vm.productsCount}},[(_vm.search)?_c('mf-info-card',{staticClass:"mb-2",attrs:{"icon":"warning","variant":"warning","description":("Exibindo resultados para '" + _vm.search + "'. Para visualizar/editar todos os produtos, limpe o campo de busca.")}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"no-data-text":_vm.noDataText,"no-results-text":_vm.noDataText},scopedSlots:_vm._u([{key:"item.product_name.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"text":"","disabled":_vm.disabled || item.$model.disabledEdit || _vm.offer.products_group,"error":item.product_name.$error,"row":item.$model.index,"tooltip":item.product_name.$error,"tooltip-text":_vm.nameAndLabelTooltip(item.product_name),"column":0},model:{value:(item.product_name.$model),callback:function ($$v) {_vm.$set(item.product_name, "$model", $$v)},expression:"item.product_name.$model"}})]}},{key:"item.label_pdv.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"text":"","disabled":_vm.disabled || item.$model.disabledEdit,"error":item.label_pdv.$error,"row":item.$model.index,"column":1,"counter":100,"tooltip":item.label_pdv.$error,"tooltip-text":_vm.nameAndLabelTooltip(item.label_pdv)},on:{"input":function (newValue) { return _vm.copyValue('label_pdv', newValue); }},model:{value:(item.label_pdv.$model),callback:function ($$v) {_vm.$set(item.label_pdv, "$model", $$v)},expression:"item.label_pdv.$model"}})]}},{key:"item.cashback_absolute_discount.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"default-number":"","disabled":_vm.disabled || item.$model.disabledEdit,"error":item.cashback_absolute_discount.$error,"row":item.$model.index,"column":2,"tooltip":item.cashback_absolute_discount.$error,"tooltip-text":"Preenchimento obrigatório."},on:{"input":function (newValue) { return _vm.copyValue('cashback_absolute_discount', newValue); }},model:{value:(item.cashback_absolute_discount.$model),callback:function ($$v) {_vm.$set(item.cashback_absolute_discount, "$model", $$v)},expression:"item.cashback_absolute_discount.$model"}})]}},{key:"item.minimum_per_client.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"disabled":_vm.disabled || item.$model.disabledEdit,"error":item.minimum_per_client.$error,"row":item.$model.index,"column":3,"tooltip":item.minimum_per_client.$error,"tooltip-text":"Mín. e Máx. precisam estar preenchidos."},on:{"input":function (newValue) { return _vm.copyValue('minimum_per_client', newValue); }},model:{value:(item.minimum_per_client.$model),callback:function ($$v) {_vm.$set(item.minimum_per_client, "$model", $$v)},expression:"item.minimum_per_client.$model"}})]}},{key:"item.max_coupons.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"disabled":_vm.disabled || item.$model.disabledEdit,"error":item.max_coupons.$error,"row":item.$model.index,"column":4,"tooltip":item.max_coupons.$error,"tooltip-text":"Mín. e Máx. precisam estar preenchidos."},on:{"input":function (newValue) { return _vm.copyValue('max_coupons', newValue); }},model:{value:(item.max_coupons.$model),callback:function ($$v) {_vm.$set(item.max_coupons, "$model", $$v)},expression:"item.max_coupons.$model"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }