var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mf-card-container',{attrs:{"title":_vm.productsCount}},[(_vm.search)?_c('mf-info-card',{staticClass:"mb-2",attrs:{"icon":"warning","variant":"warning","description":("Exibindo resultados para '" + _vm.search + "'. Para visualizar/editar todos os produtos, limpe o campo de busca.")}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"no-data-text":_vm.noDataText,"no-results-text":_vm.noDataText},scopedSlots:_vm._u([{key:"item.product_name.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"text":"","disabled":_vm.disabled || item.$model.disabledEdit || _vm.offer.products_group,"error":item.product_name.$error,"row":item.$model.index,"column":0,"tooltip":item.product_name.$error,"tooltip-text":_vm.nameAndLabelTooltip(item.product_name)},model:{value:(item.product_name.$model),callback:function ($$v) {_vm.$set(item.product_name, "$model", $$v)},expression:"item.product_name.$model"}})]}},{key:"item.label_pdv.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"text":"","disabled":_vm.disabled || item.$model.disabledEdit,"error":item.label_pdv.$error,"row":item.$model.index,"column":1,"counter":100,"tooltip":item.label_pdv.$error,"tooltip-text":_vm.nameAndLabelTooltip(item.label_pdv)},on:{"input":function (newValue) { return _vm.copyValue('label_pdv', newValue); }},model:{value:(item.label_pdv.$model),callback:function ($$v) {_vm.$set(item.label_pdv, "$model", $$v)},expression:"item.label_pdv.$model"}})]}},{key:"item.take.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"disabled":_vm.disabled || item.$model.disabledEdit,"error":item.take.$error,"row":item.$model.index,"column":2,"tooltip":item.take.$error,"tooltip-text":"Preenchimento obrigatório e precisa ser maior que o pague."},on:{"input":function (newValue) { return _vm.copyValue('take', newValue); }},model:{value:(item.take.$model),callback:function ($$v) {_vm.$set(item.take, "$model", $$v)},expression:"item.take.$model"}})]}},{key:"item.pay.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"disabled":_vm.disabled || item.$model.disabledEdit,"error":item.pay.$error,"row":item.$model.index,"column":3,"tooltip":item.pay.$error,"tooltip-text":"Preenchimento obrigatório e precisa ser menor que o leve."},on:{"input":function (newValue) { return _vm.copyValue('pay', newValue); }},model:{value:(item.pay.$model),callback:function ($$v) {_vm.$set(item.pay, "$model", $$v)},expression:"item.pay.$model"}})]}},{key:"item.maximum_per_client.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"disabled":_vm.disabled || item.$model.disabledEdit,"error":item.maximum_per_client.$error,"row":item.$model.index,"column":4},on:{"input":function (newValue) { return _vm.copyValue('maximum_per_client', newValue); }},model:{value:(item.maximum_per_client.$model),callback:function ($$v) {_vm.$set(item.maximum_per_client, "$model", $$v)},expression:"item.maximum_per_client.$model"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }