var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page-layout',{attrs:{"title":"Editar campanha"}},[(_vm.loadingData)?_c('mf-loading-dialog',{attrs:{"loading":_vm.loadingData}}):_c('div',{staticClass:"edit-trade-offer-container"},[_c('header-trade-offer',{ref:"headerTradeOffer",attrs:{"disabled":_vm.savingOffers}}),_c('campaign-period',{ref:"campaignPeriod",attrs:{"disabled":_vm.savingOffers}}),_c('div',{staticClass:"products-container"},[_c('div',{staticClass:"title-offer-type-container"},[_c('span',{staticClass:"title-span"},[_vm._v("Produtos em oferta")]),_c('span',{staticClass:"offer-type-span"},[_vm._v(_vm._s(_vm.getOfferType(_vm.offer.offer_type)))])]),_c('mf-search',{attrs:{"dense":"","background-color":"white","placeholder":"Buscar produto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',[_c('mf-info-card',{staticClass:"mb-2",attrs:{"description":"No momento não é possível fazer com que uma oferta já lançada seja alterada para ter grupo de produtos e vice-versa."}}),_c('mf-card-container',{attrs:{"title":"Agrupamento de produtos"}},[_c('mf-toggle',{staticClass:"ml-1",attrs:{"label":"Agrupar produtos em oferta","hide-details":"","disabled":""},model:{value:(_vm.offer.products_group),callback:function ($$v) {_vm.$set(_vm.offer, "products_group", $$v)},expression:"offer.products_group"}}),_c('span',{staticClass:"products-group-description"},[_vm._v("Com essa opção ativada, todos os produtos em oferta se tornam um único grupo que compartilha Reembolso, Promoção, Mínimo e Máximo.")])],1)],1),_c(_vm.productTable,{ref:"productsTable",tag:"component",attrs:{"search":_vm.search,"disabled":_vm.savingOffers}}),_c('mf-action-buttons',{attrs:{"primary-button":{
        text: 'Salvar alterações',
        isVisible: true,
        isDisabled: !this.offer._id,
        isLoading: _vm.savingOffers,
        action: _vm.save
      },"cancel-button":{
        text: 'Cancelar',
        isVisible: true,
        isDisabled: false,
        isLoading: _vm.savingOffers,
        action: _vm.navigateBack
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }