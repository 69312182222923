<template>
  <base-page-layout title="Editar campanha">
    <mf-loading-dialog v-if="loadingData" :loading="loadingData" />
    <div v-else class="edit-trade-offer-container">
      <header-trade-offer ref="headerTradeOffer" :disabled="savingOffers" />

      <campaign-period ref="campaignPeriod" :disabled="savingOffers" />

      <div class="products-container">
        <div class="title-offer-type-container">
          <span class="title-span">Produtos em oferta</span>
          <span class="offer-type-span">{{ getOfferType(offer.offer_type) }}</span>
        </div>

        <mf-search v-model="search" dense background-color="white" placeholder="Buscar produto" />
      </div>

      <div>
        <mf-info-card
          class="mb-2"
          description="No momento não é possível fazer com que uma oferta já lançada seja alterada para ter grupo de produtos e vice-versa."
        />
        <mf-card-container title="Agrupamento de produtos">
          <mf-toggle v-model="offer.products_group" class="ml-1" label="Agrupar produtos em oferta" hide-details disabled />
          <span class="products-group-description"
            >Com essa opção ativada, todos os produtos em oferta se tornam um único grupo que compartilha Reembolso, Promoção, Mínimo e Máximo.</span
          >
        </mf-card-container>
      </div>

      <component :is="productTable" ref="productsTable" :search="search" :disabled="savingOffers" />

      <mf-action-buttons
        :primary-button="{
          text: 'Salvar alterações',
          isVisible: true,
          isDisabled: !this.offer._id,
          isLoading: savingOffers,
          action: save
        }"
        :cancel-button="{
          text: 'Cancelar',
          isVisible: true,
          isDisabled: false,
          isLoading: savingOffers,
          action: navigateBack
        }"
      />
    </div>
  </base-page-layout>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia'
import { useIndustryStore } from '@/stores'
import BasePageLayout from '@/components/base/BasePageLayout.vue'
import { QUERY_INDUSTRY_OFFER, MUTATION_UPDATE_INDUSTRY_OFFER } from '@/modules/industry/graphql'
import { getOfferType } from '@/modules/industry/helpers/offerType.js'
import HeaderTradeOffer from '../components/HeaderTradeOffer.vue'
import CampaignPeriod from '../components/CampaignPeriod.vue'
import AbsoluteDiscountTable from '../components/AbsoluteDiscountTable.vue'
import PercentageDiscountTable from '../components/PercentageDiscountTable.vue'
import CashbackTable from '../components/CashbackTable.vue'
import TakePayTable from '../components/TakePayTable.vue'

export default {
  name: 'EditTradeOffer',
  components: {
    BasePageLayout,
    HeaderTradeOffer,
    CampaignPeriod
  },
  data: () => ({
    loadingData: false,
    savingOffers: false,
    search: '',
    originalOffer: {}
  }),
  computed: {
    ...mapState(useIndustryStore, ['selectedOffersGroup']),
    ...mapWritableState(useIndustryStore, ['offer']),

    productTable() {
      switch (this.offer.offer_type) {
        case 'takepay':
          return TakePayTable
        case 'nextbuycashback':
          return CashbackTable
        case 'general':
          return AbsoluteDiscountTable
        case 'percentage':
          return PercentageDiscountTable
        default:
          return null
      }
    },

    firstOfferFromSelection() {
      return this.selectedOffersGroup?.offers?.[0]
    }
  },
  async mounted() {
    await this.getOffer()
  },
  methods: {
    ...mapActions(useIndustryStore, ['setOffer']),

    getOfferType,

    async getOffer() {
      this.loadingData = true
      try {
        const response = await this.$apollo.query({
          query: QUERY_INDUSTRY_OFFER,
          fetchPolicy: 'network-only',
          variables: {
            industryOfferId: this.firstOfferFromSelection._id,
            modality: this.selectedOffersGroup.modality
          },
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })

        this.setOffer(response.data.industryOffer)
        this.originalOffer = JSON.parse(JSON.stringify(response.data.industryOffer))
      } catch (error) {
        this.$snackbar({ message: 'Erro ao carregar oferta para edição.', snackbarColor: 'error' })
      } finally {
        this.loadingData = false
      }
    },

    async save() {
      let isValidArray = []
      isValidArray.push(this.$refs.headerTradeOffer.validate())
      isValidArray.push(this.$refs.campaignPeriod.validate())
      isValidArray.push(this.$refs.productsTable.validate())

      const isValid = isValidArray.every(valid => valid)
      if (!isValid) {
        this.$snackbar({ message: 'Preencha todos os campos corretamente.', snackbarColor: 'error' })
        return
      }

      const saveData = this.compareDifferences()
      const productsDifference = this.compareProducts()

      const hasAnyUnitsDifference = saveData.hasOwnProperty('units') || saveData.hasOwnProperty('unit_tags')
      if (this.$refs.headerTradeOffer.forceUnitsUpdate || hasAnyUnitsDifference) {
        saveData.units = this.offer.units
        saveData.unit_tags = this.offer.unit_tags
      }

      if (Object.keys(saveData).length <= 0 && productsDifference.length <= 0) {
        this.$snackbar({ message: 'Nenhuma alteração identificada.', snackbarColor: 'error' })
        return
      }

      if (productsDifference.length > 0) {
        saveData[this.offer.offer_type] = productsDifference
      }

      this.savingOffers = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_INDUSTRY_OFFER,
          variables: {
            input: { data: saveData, modality: this.selectedOffersGroup.modality, offer_ids: this.selectedOffersGroup.offers.map(offer => offer._id) }
          },
          context: {
            uri: this.$microservicesUrls['accounts'],
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })
        this.$alert({
          alert_message: 'Oferta(s) marcada(s) para ser(em) editada(s) com sucesso.',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.navigateBack()
      } catch (error) {
        this.$snackbar({ message: 'Erro ao marcar oferta(s) para ser(em) editada(s).', snackbarColor: 'error' })
        console.log(error)
      } finally {
        this.savingOffers = false
      }
    },

    compareDifferences() {
      const modifiedOffer = this.offer
      const differences = {}

      this.compareObjects(this.originalOffer, modifiedOffer, differences)
      return differences
    },

    compareObjects(original, modified, differences, parentKey = '') {
      for (const key in original) {
        if (original.hasOwnProperty(key)) {
          const fullKey = parentKey ? `${parentKey}.${key}` : key
          if (Array.isArray(original[key])) {
            continue
          }
          if (typeof original[key] === 'object' && original[key] !== null) {
            this.compareObjects(original[key], modified[key], differences, fullKey)
          } else if (original[key] !== modified[key]) {
            differences[fullKey] = modified[key]
          }
        }
      }
    },

    compareProducts() {
      const originalProducts = this.originalOffer[this.offer.offer_type]
      const newProducts = this.$refs.productsTable.products
      if (this.offer.offer_type === 'nextbuycashback') {
        newProducts.forEach(product => {
          product.cashback_expiration_date = this.$refs.campaignPeriod.cashbackExpirationDate
        })
      }
      const modifiedProducts = []

      newProducts.map(newProduct => {
        const originalProduct = originalProducts.find(op => op.ean === newProduct.ean)
        const differences = {}
        this.compareObjects(originalProduct, newProduct, differences)
        if (Object.keys(differences).length > 0) {
          differences['ean'] = newProduct.ean
          modifiedProducts.push(differences)
        }
      })

      return modifiedProducts
    },

    navigateBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-trade-offer-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 8px;
}

.products-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title-offer-type-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title-span {
      font-size: 22px;
      font-weight: 700;
      color: #4b525a;
    }

    .offer-type-span {
      font-size: 18px;
      font-weight: 700;
      color: #00a186;
    }
  }
}

.products-group-description {
  font-size: 14px;
  font-weight: 400;
  color: #6b7580;
}
</style>
