<template>
  <mf-card-container title="Período de validade">
    <div class="date-pickers-container">
      <div style="width: 236px;">
        <label for="start-date">Data inicial</label>
        <div class="mt-2">
          <date-select
            id="start-date"
            v-model="offer.start_date"
            dense
            hide-buttons
            :hide-details="false"
            :filter-style="false"
            :error="startDateError.length > 0"
            :error-messages="startDateError"
            :min="todayDate.toISO()"
            :disabled="disabled"
            type="date"
          />
        </div>
      </div>
      <div style="width: 236px;">
        <label for="end-date">Data final</label>
        <div class="mt-2">
          <date-select
            id="end-date"
            v-model="offer.end_date"
            dense
            hide-buttons
            :hide-details="false"
            :error="endDateError.length > 0"
            :error-messages="endDateError"
            :filter-style="false"
            :min="offer.start_date"
            :disabled="disabled"
            type="date"
          />
        </div>
      </div>
      <v-divider vertical></v-divider>
      <div style="width: 236px;">
        <label for="proposal-deadline">Prazo de adesão</label>
        <div class="mt-2">
          <date-select
            id="proposal-deadline"
            v-model="offer.proposal_deadline"
            dense
            hide-buttons
            :hide-details="false"
            :error="proposalDeadlineError.length > 0"
            :error-messages="proposalDeadlineError"
            :filter-style="false"
            :max="offer.end_date"
            :disabled="disabled"
            type="date"
          />
        </div>
      </div>
      <div v-if="isCashbackOffer" style="width: 236px;">
        <label for="proposal-deadline">Validade do cashback</label>
        <div class="mt-2">
          <date-select
            id="proposal-deadline"
            v-model="cashbackExpirationDate"
            dense
            hide-buttons
            :hide-details="false"
            :error="cashbackExpirationDateError.length > 0"
            :error-messages="cashbackExpirationDateError"
            :filter-style="false"
            :min="offer.end_date"
            :disabled="disabled"
            type="date"
          />
        </div>
      </div>
    </div>
  </mf-card-container>
</template>

<script>
import { mapWritableState } from 'pinia'
import { useIndustryStore } from '@/stores'
import { DateTime } from 'luxon'

export default {
  name: 'CampaignPeriod',
  components: {
    DateSelect: () => import('@/modules/industry/components/Filters/DateSelect.vue')
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    cashbackExpirationDate: '',
    startDateError: '',
    endDateError: '',
    proposalDeadlineError: '',
    cashbackExpirationDateError: ''
  }),
  computed: {
    ...mapWritableState(useIndustryStore, ['offer']),
    isCashbackOffer() {
      return this.offer.offer_type === 'nextbuycashback' || this.offer.offer_type === 'cashback'
    },
    todayDate() {
      return DateTime.now()
    }
  },
  mounted() {
    if (this.isCashbackOffer) {
      this.cashbackExpirationDate = this.offer.nextbuycashback[0].cashback_expiration_date
    }
  },
  methods: {
    clearErrors() {
      this.startDateError = ''
      this.endDateError = ''
      this.proposalDeadlineError = ''
      this.cashbackExpirationDateError = ''
    },
    validate() {
      this.clearErrors()
      let isValid = true

      const startDate = DateTime.fromISO(this.offer.start_date).startOf('day')
      const endDate = DateTime.fromISO(this.offer.end_date).endOf('day')
      const proposalDeadline = DateTime.fromISO(this.offer.proposal_deadline).endOf('day')

      if (!startDate || startDate.invalid) {
        this.startDateError = 'É necessário definir uma data de início.'
        isValid = false
      }

      if (!endDate || endDate.invalid) {
        this.endDateError = 'É necessário definir uma data de fim.'
        isValid = false
      }

      if (startDate > endDate) {
        this.startDateError = 'A data inicial não pode ser maior que a data final.'
        isValid = false
      }

      if (endDate < startDate) {
        this.endDateError = 'A data final não pode ser menor que a data inicial.'
        isValid = false
      }

      if (startDate < this.todayDate.startOf('day')) {
        this.startDateError = 'A data inicial não pode ser menor que hoje.'
        isValid = false
      }

      if (!proposalDeadline || proposalDeadline.invalid) {
        this.proposalDeadlineError = 'É necessário definir um prazo de adesão.'
        isValid = false
      }

      if (proposalDeadline > endDate.endOf('day')) {
        this.proposalDeadlineError = 'O prazo de adesão deve ser anterior ou igual ao fim da campanha.'
        isValid = false
      }

      if (this.isCashbackOffer) {
        const cashbackDate = DateTime.fromISO(this.cashbackExpirationDate).endOf('day')
        if (!cashbackDate || cashbackDate.invalid) {
          this.cashbackExpirationDateError = 'É necessário definir uma validade do cashback.'
          isValid = false
        }

        if (cashbackDate < endDate) {
          this.cashbackExpirationDateError = 'O prazo de vencimento de cashback deve ser posterior ou igual ao fim da campanha.'
          isValid = false
        }
      }

      return isValid
    }
  }
}
</script>

<style lang="scss" scoped>
.date-pickers-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
</style>
