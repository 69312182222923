import { parseNumber } from '@/helpers/validators.js'

export const price1Validation = () => (value, vm) => {
  if (!value || value < 0) return false
  if (!vm.price_2) return value > 0
  return value >= vm.price_2
}

export const price2Validation = () => (value, vm) => {
  if (!value || value < 0) return false
  return value <= vm.price_1
}

export const rebateValidation = () => (value, vm) => {
  if (!value) return false
  return value <= vm.price_1
}

export const notOnlySpace = value => {
  if (!value) return true
  const valueTotest = value.trim()
  return Boolean(valueTotest.length)
}

export const percentageValidation = value => {
  if (!value || value <= 0) return false
  if (value > 100) return false
  return true
}

export const cashbackValidation = value => {
  if (!value || value <= 0) return false
  return true
}

export const takeAndPayValidation = (value, product) => {
  const take = parseNumber(product.take, 'int')
  const pay = parseNumber(product.pay, 'int')

  if (!take || !pay) return false

  return take > pay
}

export const minMaxPerClientValidation = (value, product) => {
  const min = product.minimum_per_client
  const max = product.maximum_per_client

  if (!min || !max) return true

  return max >= min
}

export const minMaxPerClientValidationNextbuy = (value, product) => {
  const min = product.minimum_per_client
  const max = product.max_coupons

  if (min && max) return true
  return false
}
