<template>
  <mf-card-container :title="productsCount">
    <mf-info-card
      v-if="search"
      class="mb-2"
      icon="warning"
      variant="warning"
      :description="`Exibindo resultados para '${search}'. Para visualizar/editar todos os produtos, limpe o campo de busca.`"
    />
    <v-data-table :headers="headers" :items="items" :search="search" :no-data-text="noDataText" :no-results-text="noDataText">
      <template #item.product_name.$model="{ item }">
        <field-toggle
          v-model="item.product_name.$model"
          text
          :disabled="disabled || item.$model.disabledEdit || offer.products_group"
          :error="item.product_name.$error"
          :row="item.$model.index"
          :tooltip="item.product_name.$error"
          :tooltip-text="nameAndLabelTooltip(item.product_name)"
          :column="0"
        />
      </template>

      <template #item.label_pdv.$model="{ item }">
        <field-toggle
          v-model="item.label_pdv.$model"
          text
          :disabled="disabled || item.$model.disabledEdit"
          :error="item.label_pdv.$error"
          :row="item.$model.index"
          :column="1"
          :counter="100"
          :tooltip="item.label_pdv.$error"
          :tooltip-text="nameAndLabelTooltip(item.label_pdv)"
          @input="newValue => copyValue('label_pdv', newValue)"
        />
      </template>

      <template #item.cashback_absolute_discount.$model="{ item }">
        <field-toggle
          v-model="item.cashback_absolute_discount.$model"
          default-number
          :disabled="disabled || item.$model.disabledEdit"
          :error="item.cashback_absolute_discount.$error"
          :row="item.$model.index"
          :column="2"
          :tooltip="item.cashback_absolute_discount.$error"
          tooltip-text="Preenchimento obrigatório."
          @input="newValue => copyValue('cashback_absolute_discount', newValue)"
        />
      </template>

      <template #item.minimum_per_client.$model="{ item }">
        <field-toggle
          v-model="item.minimum_per_client.$model"
          :disabled="disabled || item.$model.disabledEdit"
          :error="item.minimum_per_client.$error"
          :row="item.$model.index"
          :column="3"
          :tooltip="item.minimum_per_client.$error"
          tooltip-text="Mín. e Máx. precisam estar preenchidos."
          @input="newValue => copyValue('minimum_per_client', newValue)"
        />
      </template>

      <template #item.max_coupons.$model="{ item }">
        <field-toggle
          v-model="item.max_coupons.$model"
          :disabled="disabled || item.$model.disabledEdit"
          :error="item.max_coupons.$error"
          :row="item.$model.index"
          :column="4"
          :tooltip="item.max_coupons.$error"
          tooltip-text="Mín. e Máx. precisam estar preenchidos."
          @input="newValue => copyValue('max_coupons', newValue)"
        />
      </template>
    </v-data-table>
  </mf-card-container>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { minMaxPerClientValidationNextbuy, cashbackValidation, notOnlySpace } from '@/modules/industry/helpers/productValidators.js'
import { editProductsTable } from '@/modules/industry/mixins/edit-products-table'

export default {
  name: 'CashbackTable',
  mixins: [editProductsTable],
  computed: {
    headers() {
      let headers = [
        {
          text: 'EAN',
          align: 'start',
          sortable: false,
          value: 'ean.$model',
          width: '10%'
        },
        {
          text: 'Nome',
          align: 'start',
          sortable: false,
          value: 'product_name.$model',
          width: '31%'
        }
      ]

      if (this.isEditingMercapromo) {
        headers = [
          ...headers,
          {
            text: 'Label PDV',
            align: 'start',
            sortable: false,
            value: 'label_pdv.$model',
            width: '23%'
          }
        ]
      }

      headers = [
        ...headers,
        {
          text: 'Cashback (R$)',
          align: 'start',
          sortable: false,
          value: 'cashback_absolute_discount.$model',
          width: '10%'
        },
        {
          text: 'Mín. CPF',
          align: 'start',
          sortable: false,
          value: 'minimum_per_client.$model',
          width: '9%'
        },
        {
          text: 'Máx. Cupons',
          align: 'start',
          sortable: false,
          value: 'max_coupons.$model',
          width: '9%'
        }
      ]
      return headers
    }
  },
  validations() {
    return {
      products: {
        $each: {
          ean: {},
          product_name: {
            notOnlySpace,
            required: requiredIf(editingProduct => this.isProductNameRequired(editingProduct))
          },
          label_pdv: {
            notOnlySpace,
            required: requiredIf(() => {
              return this.isEditingMercapromo
            })
          },
          cashback_absolute_discount: {
            cashbackValidation
          },
          minimum_per_client: {
            minMaxPerClientValidationNextbuy
          },
          max_coupons: {
            minMaxPerClientValidationNextbuy
          }
        }
      }
    }
  }
}
</script>
