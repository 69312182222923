var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mf-card-container',{attrs:{"title":_vm.productsCount}},[(_vm.search)?_c('mf-info-card',{staticClass:"mb-2",attrs:{"icon":"warning","variant":"warning","description":("Exibindo resultados para '" + _vm.search + "'. Para visualizar/editar todos os produtos, limpe o campo de busca.")}}):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"no-data-text":_vm.noDataText,"no-results-text":_vm.noDataText},scopedSlots:_vm._u([{key:"item.product_name.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"text":"","disabled":_vm.disabled || item.$model.disabledEdit || _vm.offer.products_group,"error":item.product_name.$error,"row":item.$model.index,"column":0,"tooltip":item.product_name.$error,"tooltip-text":_vm.nameAndLabelTooltip(item.product_name)},model:{value:(item.product_name.$model),callback:function ($$v) {_vm.$set(item.product_name, "$model", $$v)},expression:"item.product_name.$model"}})]}},{key:"item.label_pdv.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"text":"","disabled":_vm.disabled || item.$model.disabledEdit,"error":item.label_pdv.$error,"row":item.$model.index,"column":1,"counter":100,"tooltip":item.label_pdv.$error,"tooltip-text":_vm.nameAndLabelTooltip(item.label_pdv)},on:{"input":function (newValue) { return _vm.copyValue('label_pdv', newValue); }},model:{value:(item.label_pdv.$model),callback:function ($$v) {_vm.$set(item.label_pdv, "$model", $$v)},expression:"item.label_pdv.$model"}})]}},{key:"item.price_1.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"money":"","disabled":_vm.disabled || item.$model.disabledEdit,"error":item.price_1.$error,"row":item.$model.index,"column":2,"tooltip":item.price_1.$error,"tooltip-text":"Preenchimento obrigatório e precisa ser maior ou igual ao preço 2."},on:{"input":function (newValue) { return _vm.copyValue('price_1', newValue); }},model:{value:(item.price_1.$model),callback:function ($$v) {_vm.$set(item.price_1, "$model", $$v)},expression:"item.price_1.$model"}})]}},{key:"item.price_2.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"money":"","disabled":_vm.disabled || item.$model.disabledEdit,"error":item.price_2.$error,"row":item.$model.index,"column":3,"tooltip":item.price_2.$error,"tooltip-text":"Preenchimento obrigatório e precisa ser menor ou igual ao preço 1."},on:{"input":function (newValue) { return _vm.copyValue('price_2', newValue); }},model:{value:(item.price_2.$model),callback:function ($$v) {_vm.$set(item.price_2, "$model", $$v)},expression:"item.price_2.$model"}})]}},{key:"item.rebate.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"money":"","disabled":_vm.disabled || item.$model.disabledEdit,"error":item.rebate.$error,"row":item.$model.index,"column":4,"tooltip":item.rebate.$error,"tooltip-text":"Preenchimento obrigatório e precisa ser menor ou igual ao preço 1."},on:{"input":function (newValue) { return _vm.copyValue('rebate', newValue); }},model:{value:(item.rebate.$model),callback:function ($$v) {_vm.$set(item.rebate, "$model", $$v)},expression:"item.rebate.$model"}})]}},{key:"item.minimum_per_client.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"disabled":_vm.disabled || item.$model.disabledEdit,"error":item.minimum_per_client.$error,"row":item.$model.index,"column":5,"tooltip":item.minimum_per_client.$error,"tooltip-text":"Mínimo precisa ser menor que máximo."},on:{"input":function (newValue) { return _vm.copyValue('minimum_per_client', newValue); }},model:{value:(item.minimum_per_client.$model),callback:function ($$v) {_vm.$set(item.minimum_per_client, "$model", $$v)},expression:"item.minimum_per_client.$model"}})]}},{key:"item.maximum_per_client.$model",fn:function(ref){
var item = ref.item;
return [_c('field-toggle',{attrs:{"disabled":_vm.disabled || item.$model.disabledEdit,"error":item.maximum_per_client.$error,"row":item.$model.index,"column":6,"tooltip":item.maximum_per_client.$error,"tooltip-text":"Máximo precisa ser maior que mínimo."},on:{"input":function (newValue) { return _vm.copyValue('maximum_per_client', newValue); }},model:{value:(item.maximum_per_client.$model),callback:function ($$v) {_vm.$set(item.maximum_per_client, "$model", $$v)},expression:"item.maximum_per_client.$model"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }